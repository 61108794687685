
import axiostw_mobileapp from '../../config/axios-mobileapp';

const customerAll = async (data) => {

    try {
        const result = await axiostw_mobileapp.get(`/api/Manage-customer/customer?page=${data}`)
        return result && result.data
    } catch (error) {
        return error && error.response && error.response.data
    }
}
const customerByid = async (data) => {
    try {
        const result = await axiostw_mobileapp.get(`/api/Manage-customer/customer/${data}`)
        return result && result.data
    } catch (error) {
        return error && error.response && error.response.data
    }
}
const updateCustomerdata = async (data,id) => {
    try {
        const result = await axiostw_mobileapp.post(`/api/Manage-customer/customer/update/${id}`,data)
        return result && result.data
    } catch (error) {
        return error && error.response && error.response.data
    }
}
const customerSearchKeyword = async (data) => {
    try {
        const result = await axiostw_mobileapp.get(`/api/Manage-customer/customer/search?query=${data}`)
        return result && result.data
    } catch (error) {
        return error && error.response && error.response.data
    }
}
const creditsHistory = async (data) => {
    try {
        const result = await axiostw_mobileapp.get(`/api/Manage-customer/credits/history?page=${data}`)
        return result && result.data
    } catch (error) {
        return error && error.response && error.response.data
    }
}
const creditsHistoryByid = async (data,page) => {
    try {
        const result = await axiostw_mobileapp.get(`/api/Manage-customer/credits/history/${data}?page=${page}`)
        return result && result.data
    } catch (error) {
        return error && error.response && error.response.data
    }
}
const customerUpdateStatus = async (data) => {
    try {
        const result = await axiostw_mobileapp.patch(`/api/Manage-customer/customer/status`,data)
        return result && result.data
    } catch (error) {
        return error && error.response && error.response.data
    }
}
const addCreditHistory = async (data) => {
    try {
        const result = await axiostw_mobileapp.post(`/api/Manage-customer/credits/addCreditHistory`,data)
        return result && result.data
    } catch (error) {
        return error && error.response && error.response.data
    }
}
const get_history = async (data) => {

    try {
        const result = await axiostw_mobileapp.get(`/api/Manage-customer/credits/get_history?startDate=${data.startDate}&endDate=${data.endDate}`)
        return result && result.data
    } catch (error) {
        return error && error.response && error.response.data
    }
}
const export_history = async (data) => {
try {
        const result = await axiostw_mobileapp.get(`/api/Manage-customer/credits/export_history?startDate=${data.startDate}&endDate=${data.endDate}`)
        return result && result.data
    } catch (error) {
        return error && error.response && error.response.data
    }
}
const export_Usagehistory = async (data) => {
    try {
            const result = await axiostw_mobileapp.get(`/api/Manage-customer/credits/export_UsageCredit?startDate=${data.startDate}&endDate=${data.endDate}`)
            return result && result.data
        } catch (error) {
            return error && error.response && error.response.data
        }
    }
    const get_Usagehistory = async (data) => {

        try {
            const result = await axiostw_mobileapp.get(`/api/Manage-customer/credits/UsageCredit?startDate=${data.startDate}&endDate=${data.endDate}`)
            return result && result.data
        } catch (error) {
            return error && error.response && error.response.data
        }
    }
export {
    get_history,export_history,get_Usagehistory, export_Usagehistory,customerAll,updateCustomerdata,customerSearchKeyword,creditsHistory,addCreditHistory,customerUpdateStatus,creditsHistoryByid , customerByid
}