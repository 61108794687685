import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import PrivateRoute from "./PrivateRoute";

import DashboardNewBranchAdmin from "../pages/Dashboard/BranchAdmin/DashboardNew";
import DashboardSuperAdminV2 from "../pages/Dashboard/SuperAdmin/Dashboard-v2";
import DashboardBranchAdminV2 from "../pages/Dashboard/BranchAdmin/Dashboard-v2";
import UsageCredit from "../pages/UsageCredit/UsageCredit"
import UploadSlip from "../pages/UploadSlip/UploadSlip";

import TransactionListV2 from "../pages/Transaction/TransactionList-v2";

import MachineListPageV2 from "../pages/Machine/MachineListPage-v2";
import MachineAddEditPageV2 from "../pages/Machine/MachineAddEditPage-v2";
import MachineHistoryPageV2 from "../pages/Machine/MachineHistoryPage-v2";
import MachineControllerPageV2 from "../pages/Machine/MachineControllerPage-v2";


import CustomerListV2 from "../pages/Customer/CustomerList-v2";
import CustomerAddEditV2 from "../pages/Customer/CustomerAddEdit-v2";
import CustomerViewV2 from "../pages/Customer/CustomerView-v2";

import SiteManagementListV2 from "../pages/SiteManagement/SiteManagementList-v2";
import SiteManagementAddEditV2 from "../pages/SiteManagement/SiteManagementAddEdit-v2";

import AdminListV2 from "../pages/AdminManagement/AdminList-v2";
import AdminAddEditV2 from "../pages/AdminManagement/AdminAddEdit-v2";

import ReportNew from "../pages/Report/ReportNew";
import ReportV2 from "../pages/Report/Report-v2";

const Router = () => {
  const data = useSelector((state) => state.data);

  const menuList = (localStorage.getItem("list") || "").split(",") || "";
  const role = (localStorage.getItem("user_role") || "").toUpperCase();
  const site = localStorage.getItem("site_id") || "";

  console.log("menuList--👻👻", menuList, role);
  const getRedirect = () => {
    switch (role) {
      case "BRANCHADMIN":
        return `/dashboard/v2/${site}`;
      case "SUPERADMIN":
        return "/dashboards/v2";
      default:
        return `/dashboards/v2`; // return lowest
    }
  };

  return (
    <div
      className="ctn-wo-nvgt"
      style={{ marginLeft: data.hideNav ? "60px" : null }}
    >
      <Switch>
        <Route exact path="/">
          <Redirect to={getRedirect()} />
        </Route>

        <PrivateRoute
          exact
          role={["BRANCHADMIN", "SUPERADMIN"]}
          path="/dashboard-new/:siteId"
          component={DashboardNewBranchAdmin}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/dashboards/v2"
          component={DashboardSuperAdminV2}
        />
        <PrivateRoute
          exact
          role={["BRANCHADMIN", "SUPERADMIN"]}
          path="/dashboard/v2/:siteId"
          component={DashboardBranchAdminV2}
        />

        {/* Deverlopment Screen */}
        <PrivateRoute
          exact
          role={["BRANCHADMIN", "SUPERADMIN"]}
          path="/slip/:siteId"
          component={UploadSlip}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/transaction/list/v2"
          component={TransactionListV2}
        />
        <PrivateRoute
          exact
          role={["BRANCHADMIN"]}
          path="/report/v2/:siteId"
          component={ReportV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-management/list/report-new/:siteId"
          component={ReportNew}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-management/list/report/v2/:siteId"
          component={ReportV2}
        />
        <PrivateRoute
          exact
          role={["BRANCHADMIN", "SUPERADMIN"]}
          path="/machine/list/v2"
          component={MachineListPageV2}
        />
        <PrivateRoute
          exact
          role={["BRANCHADMIN", "SUPERADMIN"]}
          path="/machine/list/history/v2/:machineId"
          component={MachineHistoryPageV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/add/v2"
          component={MachineAddEditPageV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/list/edit/v2/:machineId"
          component={MachineAddEditPageV2}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/machine/controller/v2"
          component={MachineControllerPageV2}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/customer/list/v2"
          component={CustomerListV2}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/customer/list/v2/:customerId"
          component={CustomerViewV2}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/customer/add/v2"
          component={CustomerAddEditV2}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/customer/list/edit/v2/:customerId"
          component={CustomerAddEditV2}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-management/list/v2"
          component={SiteManagementListV2}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-management/add/v2"
          component={SiteManagementAddEditV2}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/site-management/v2/list/edit/:siteId"
          component={SiteManagementAddEditV2}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/list-admin/v2"
          component={AdminListV2}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/list-admin/v2/:siteId"
          component={AdminListV2}
        />
<PrivateRoute
          exact
          role={["BRANCHADMIN"]}
          path="/UsageCredit"
          component={UsageCredit}
        />
        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/add-admin/v2"
          component={AdminAddEditV2}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/add-admin/v2/:siteId"
          component={AdminAddEditV2}
        />

        <PrivateRoute
          exact
          role={["SUPERADMIN"]}
          path="/admin-management/list-admin/edit/v2/:adminId"
          component={AdminAddEditV2}
        />

        {/* <Redirect to="/" /> */}
      </Switch>
    </div>
  );
};

export default Router;
