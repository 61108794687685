import React, { useState, useEffect } from "react";
import { Table, Pagination } from "antd";
import "../../Table.css";
import { numberWithCommas } from "../../../../utils/function";

const TableReportGroupV2 = ({ dataReport }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [tableData, setTableData] = useState(undefined);
  const [dataList, setDataList] = useState(undefined);

  // const [waitingForCSV, setWaitingForCSV] = useState(false)

  

  useEffect(() => {
    if (dataReport?.data?.getHistoryByPrice) {
      const dataArr = dataReport?.data?.getHistoryByPrice ?? [];

      if (dataArr?.length > 0) {
        let newData = [];
        let dataNew = [];
        dataArr.map((item) => {
          if (item?.price_group?.length > 0) {
            item?.price_group.map((group) => {
              const _id = `${item?._id}_${
                group?.machine == "เครื่องอบผ้า"
                  ? 1
                  : group?.machine == "เครื่องซักผ้า"
                  ? 2
                  : 0
              }`;
              if (dataNew?.length > 0) {
                dataNew.map((arr) => {
                  if (arr.id === _id) {
                    arr.data.push(group);
                  } else {
                    const checkDup = newData.includes(_id);
                    if (!checkDup) {
                      dataNew.push({
                        id: _id,
                        priceMachine: item?._id,
                        data: [group],
                      });
                      newData.push(_id);
                    }
                  }
                });
              } else {
                dataNew.push({
                  id: _id,
                  priceMachine: item?._id,
                  data: [group],
                });
                newData.push(_id);
              }
            });
          }
        });
        setDataList(dataNew);
      }
    }
  }, [dataReport]);

  useEffect(() => {
    if (dataList?.length > 0) {
      let dataArrNew = [];
      dataList.map((item) => {
        if (item?.data?.length > 0) {
          let incomeTotalValue = 0;
          let coinValue = 0;
          let mobileValue = 0;
          let qrCodeValue = 0;
          let countsValue = 0;
          let machine_name = "";

          item.data?.map((data) => {
            if (
              data?.action == "NOP" ||
              data?.action == "coin" ||
              data?.action == "Coin"
            ) {
              coinValue += parseInt(data?.values);
            } else if (data?.action == "mobile" || data?.action == "Mobile") {
              mobileValue += parseInt(data?.values);
            } else if (
              data?.action == "tw_pay" ||
              data?.action == "QR-Code" ||
              data?.action == "QR" ||
              data?.action == "qr" ||
              data?.action == "qrcode"
            ) {
              qrCodeValue += parseInt(data?.values);
            }
            incomeTotalValue += parseInt(data?.values);
            countsValue += parseInt(data?.counts);
            machine_name = `${data?.machine} ${item?.priceMachine} บาท`;
          });
          dataArrNew.push({
            coin: coinValue,
            mobile: mobileValue,
            qrCode: qrCodeValue,
            total: incomeTotalValue,
            counts: countsValue,
            machineName: machine_name,
          });
          setTableData(dataArrNew);
        }
      });
    }
  }, [dataList]);

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "เครื่อง",
      ellipsis: true,
      render: (data) => <div>{data.machineName || "-"}</div>,
    },
    {
      title: "จำนวนครั้ง",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.counts || 0, 0)}</div>,
    },
    {
      title: "Coin",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.coin || 0, 2)}</div>,
    },
    {
      title: "APP",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.mobile || 0, 2)}</div>,
    },
    {
      title: "QR Code",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.qrCode || 0, 2)}</div>,
    },
    {
      title: "รวม",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.total || 0, 2)}</div>,
    },
  ];

  return (
    <div className="dash-chart-box-container">
      <div className="dash-space-between">
        <div className="h-d-t-g">
          <div className="f-c-c">
            <i className="bi bi-journal-text" style={{ lineHeight: "0" }}></i>
          </div>
          <div className="f-s-c">รายงานการใช้งานแยกตามราคา</div>
        </div>

        <div className="dash-table-container">
          <Table
            bordered
            size="small"
            rowKey={(record) => record.id}
            loading={isLoading}
            dataSource={tableData || []}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
          />
        </div>

        <div className="pgnt-st">
          <Pagination
            size="small"
            current={currentPage}
            onChange={(page) => setCurrentPage(page)}
            total={tableData?.length}
            pageSize={perPage}
            showSizeChanger={false}
          />
        </div>
      </div>
    </div>
  );
};

export default TableReportGroupV2;
