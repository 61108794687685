import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Table,
  DatePicker,
  Select,
  Input,
  Pagination,
  Tooltip,
  Modal,
} from "antd";
import "./Transaction.css";
import XLSX from "xlsx";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";

import {
  numberWithCommas,
  getSearchParams,
  openNotification,
} from "../../utils/function";
import {
  apiTRSTGetTransaction,
  apiTRSTDel,
  apiSCBTransactionId,
} from "../../services/apis/transaction";
import { getTransationcenterReportSite } from "../../services/apis/report";
const { RangePicker } = DatePicker;
const { Option } = Select;

const Transactiontable = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const pmt = params.get("pmt");
  const start = params.get("start");
  const end = params.get("end");
  const sw = params.get("sw");

  const menuList = (localStorage.getItem("list") || "").split(",");

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);
  const [perPage, setPerPage] = useState(Number(ttl) || 20);
  const [startDate, setStartDate] = useState(
    start
      ? moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    end
      ? moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [tableData, setTableData] = useState(undefined);
  const [tableDatalow, setTableDatalow] = useState(undefined);
  const [filterOption, setFilterOption] = useState(pmt || "");
  const [searchWord, setSearchWord] = useState(sw || "");
  const [searchButton, setSearchButton] = useState(false);
  const [waitingForCSV, setWaitingForCSV] = useState(false);
  const [waitingForDel, setWaitingForDel] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalId, setModalId] = useState(undefined);
  const [visibleTnxId, setVisibleTnxId] = useState(false);
  const [transactionId, setTransactionId] = useState("");

  useEffect(() => {
    getReport();
    history.push(
      `${location.pathname}${getSearchParams(
        ["p", "ttl", "pmt", "start", "end", "sw"],
        [currentPage, perPage, filterOption, startDate, endDate, searchWord]
      )}`
    );
  }, [currentPage, perPage, searchButton]);

  const getReport = async () => {
    setIsLoading(true);
    let date = {
      "startDate": startDate,
      "endDate": endDate
    }
    let transaction = await getTransationcenterReportSite(date);
    console.log(transaction.data.length)
    if (transaction) {
      // console.log(transaction.data)
      setTableDatalow(transaction.data.length);
      setIsLoading(false);
      setTableData(transaction.data);
    }
  };

  const paymentType = (paymentType) => {
    switch (paymentType) {
      case "coin":
        return "Coin";
      case "mobile":
        return "Mobile";
      case "tw_pay":
        return "TW Pay";
      default:
        return "-";
    }
  };

  const selectDelete = [];
  const handleOnChange = (event) => {
    if (selectDelete.indexOf(event.transaction_id) > -1) {
      selectDelete.splice(selectDelete.indexOf(event.transaction_id), 1);
    } else {
      selectDelete.push(event.transaction_id);
    }
    console.log("Selected : " + selectDelete.length);
  };

  const deleteData = async () => {
    if (!modalId) {
      openNotification("error", "Failed !", "Failed to delete this machine.");
      return;
    }
    let admin_id = localStorage.getItem("user_id");
    setIsLoadingConfirm(true);
    let action = await apiTRSTDel(modalId, admin_id);
    if (action && action.status) {
      openNotification(
        "success",
        "Success !",
        "Delete this machine successful."
      );
      setVisible(false);
      setIsLoadingConfirm(false);
      getReport();
    } else {
      openNotification("error", "Failed !", "Failed to delete this machine.");
      setIsLoadingConfirm(false);
    }
  };

  const getTransactionId = async (tnx_id) => {
    setIsLoadingConfirm(true);
    let action = await apiSCBTransactionId(tnx_id);
    if (action?.data?.transaction_id) {
      setTransactionId(action?.data?.transaction_id ?? "");
      setVisibleTnxId(true);
      setIsLoadingConfirm(false);
    } else {
      setIsLoadingConfirm(false);
    }
  };

  const columns = [
    {
      title: "",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, index) => (
        <div>
          <input
            type="checkbox"
            id={`select-${data.transaction_id}`}
            name="checkDel"
            onClick={() => handleOnChange(index)}
          />
        </div>
      ),
    }, {
      title: "ประเภท",
      ellipsis: true,
      render: (data) => <div>{data.side_type || "-"}</div>,
    },
    {
      title: "รหัสสาขา",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data?.site_code || "Error"}
        </div>
      ),
    },
    {
      title: "สาขา",
      ellipsis: true,
      render: (data) => <div>{data.site_name || "-"}</div>,
    },
    {
      title: "สถาณะ",
      ellipsis: true,
      render: (data) => <div>{data.site_status || "-"}</div>,
    },
    {
      title: "coin",
      ellipsis: true,
      render: (data) => <div>
        {data.coin || "-"}
      </div>
    },
    {
      title: "mobile",
      ellipsis: true,
      render: (data) => <div>
        {data.mobile || "-"}
      </div>
    },
    {
      title: "qr_code",
      ellipsis: true,
      render: (data) => <div>
        {data.qr_code || "-"}
      </div>
    },
    {
      title: "Total ",
      ellipsis: true,
      render: (data) => <div>
        {data.coin + data.mobile + data.qr_code || "-"}
      </div>
    },
    {
      title: "Qrcode-scb ",
      ellipsis: true,
      render: (data) => <div>
      {data.data_transactions_scb || "-"}
    </div>
    }
  ];
  const deleteSelected = async () => {
    setWaitingForDel(true);
    let admin_id = localStorage.getItem("user_id");
    let loop = 0;
    await selectDelete.forEach(async (value) => {
      const action = await apiTRSTDel(value, admin_id);
      loop = loop + 1;
      if (action && action.status) {
        console.log("Delete " + value + "this machine successful.");
      } else {
        openNotification("error", "Failed !", "Failed to delete this machine.");
      }

      if (loop >= selectDelete.length) {
        console.log("Load...");
        getReport();
      }
    });
    setWaitingForDel(false);
  };
  const toCSVFile = async () => {
    setWaitingForCSV(true);
    let date = {
      "startDate": startDate,
      "endDate": endDate
    }
    let transaction = await getTransationcenterReportSite(date);
    let csvData = [];
    let data = transaction.data;
    if (data) {
      (data || []).map((data, idx) => {
        let resultRow = {};
        resultRow["site_qr_code"] = data.site_qr_code || "-"
        resultRow["ประเภท"] = data.side_type || "-"
        resultRow["รหัสสาขา"] = ` ${data?.site_code || "-"}`;
        resultRow["สาขา"] = data.site_name || "-"
        resultRow["สถาณะ"] = data.site_status || "-"
        resultRow["coin"] = data.coin || "-"
        resultRow["mobile"] = data.mobile || "-";
        resultRow["qr_code"] = data.qr_code || "-";
        resultRow["total"] = data.coin + data.mobile + data.qr_code || "-";
        resultRow["qr_code_scb"] = data.data_transactions_scb || "-";
        resultRow["เปอร์เซ็นต์"] = "-";
        resultRow["ส่วนแบ่ง"] = "-";
        csvData.push(resultRow);
      });

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = "transation_site";
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false);
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Transaction Site Report</div>

      <div className="h-d-t-g-w-d-d-s">
        <div className="h-d-t-g-w-d-d-s-order-2">
          <Select
            value={filterOption}
            style={{ width: "100%" }}
            onChange={(value) => {
              setCurrentPage(1);
              setFilterOption(value);
            }}
          >
            <Option value="">All Payment Type</Option>
            <Option value="coin">Coin</Option>
            <Option value="mobile">Mobile</Option>
            <Option value="tw_pay">TW Pay</Option>
          </Select>
        </div>
        <div className="h-d-t-g-w-d-d-s-order-1">
        <RangePicker
            style={{ width: "100%" }}
            // format={(date) =>  moment(date).add(543, "years").format("D MMM YYYY HH:mm") }
            allowClear={false}
            showTime={{ format: "HH:mm" }}
            value={[
              moment(startDate, "YYYY-MM-DD HH:mm:ss"),
              moment(endDate, "YYYY-MM-DD HH:mm:ss"),
            ]}
            onChange={(date) => {
              setCurrentPage(1);
              setStartDate(
                moment(date[0]).startOf("second").format("YYYY-MM-DD HH:mm:ss")
              );
              setEndDate(
                moment(date[1]).endOf("second").format("YYYY-MM-DD HH:mm:ss")
              );
            }}
            disabledDate={(current) =>
              current && current >= moment().endOf("day")
            }
          />
        </div>
        <div className="h-d-t-g-w-d-d-s-order-3">
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1);
              setSearchButton(!searchButton);
            }}
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-d-t-g-w-d-d-s-order-4"
          onClick={() => {
            setCurrentPage(1);
            setSearchButton(!searchButton);
          }}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-32 h-d-t-g-w-d-d-s-order-5"
          style={{ pointerEvents: waitingForCSV ? "none" : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : "Export CSV"}
        </div>
        {/* <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-32 h-d-t-g-w-d-d-s-order-5"
          style={{ pointerEvents: waitingForDel ? "none" : null }}
          onClick={() => deleteSelected()}
        >
          {waitingForDel ? <LoadingOutlined /> : "Delete Select"}
        </div> */}
      </div>

      <Table
        bordered
        size="small"
        loading={isLoading}
        rowKey={(record) => record.transaction_id}
        dataSource={(tableData) || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />

      {/* <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={(page) => setCurrentPage(page)}
          total={tableData?.length}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div> */}
    </div>
  );
};

export default Transactiontable;

