import React, { useState, useEffect } from "react";
import { Table, Pagination } from "antd";
import "../../Table.css";
// import XLSX from "xlsx";
// import { LoadingOutlined } from '@ant-design/icons';

// import moment from 'moment';
// import 'moment/locale/th'

import { apiDBGetReportMachineBySiteId } from "../../../../services/apis/dashboard";
import { numberWithCommas } from "../../../../utils/function";
import usePagination from "../../../../hooks/usePagination";

const TableReportMachineV2 = ({ dataList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20;
  const [dataArrList, setDataArrList] = useState([]);
  const [reportDevice, setReportDevice] = useState([]);
  const [dataListAll, setDataListAll] = useState([]);
  const checkDataPage = usePagination(reportDevice, currentPage, perPage);

  useEffect(() => {
    setDataListAll(checkDataPage ?? []);
  }, [currentPage, perPage, reportDevice]);

  useEffect(() => {
    if (dataList?.length > 0) {
      let dataArr = [];
      let dataNew = [];
      dataList.map((item) => {
        if (dataArr?.length > 0) {
          dataArr.map((arr) => {
            if (arr.machineId === item?.machineId) {
              arr.data.push(item);
            } else {
              const checkDup = dataNew.includes(item?.machineId);
              if (!checkDup) {
                dataArr.push({
                  machineId: item?.machineId,
                  machineName: item?.machine_name,
                  data: [item],
                  machine_type: item?.machine_type || ""
                });
                dataNew.push(item?.machineId);
              }
            }
          });
        } else {
          dataArr.push({
            machineId: item?.machineId,
            machineName: item?.machine_name,
            data: [item],
            machine_type: item?.machine_type || ""
          });
          dataNew.push(item?.machineId);
        }
      });
      setDataArrList(dataArr);
    }
  }, [dataList]);

  useEffect(() => {
    if (dataArrList?.length > 0) {
      let dataArrNew = [];
      dataArrList.map((item) => {
        if (item?.data?.length > 0) {
          let incomeTotalValue = 0;
          let coinValue = 0;
          let mobileValue = 0;
          let qrCodeValue = 0;

          item.data?.map((data) => {
            if (data?.action_by == "NOP" || data?.action_by == "coin") {
              coinValue += parseInt(data?.run_value);
            } else if (
              data?.action_by == "mobile" ||
              data?.action_by == "Mobile"
            ) {
              mobileValue += parseInt(data?.run_value);
            } else if (
              data?.action_by == "tw_pay" ||
              data?.action_by == "QR-Code" ||
              data?.action_by == "QR"||
              data?.action_by == "qr"||
              data?.action_by == "qrcode"
            ) {
              qrCodeValue += parseInt(data?.run_value);
            }
            incomeTotalValue += parseInt(data?.run_value);
          });
          dataArrNew.push({
            coin: coinValue,
            mobile: mobileValue,
            qrCode: qrCodeValue,
            total: incomeTotalValue,
            numberTimes: item?.data?.length,
            machineName: item?.machineName,
            machineId: item?.machineId,
            machine_type: item?.machine_type || ""
          });

          // console.log("dataArrNew--🐳🐳", dataArrNew);
          setReportDevice(dataArrNew);
        }
      });
    }
  }, [dataArrList]);

  const columnsGraphQL = [
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "เครื่อง",
      ellipsis: true,
      render: (data) => <div>{data.machine_type || " "} {data.machineName || "-"}</div>,
    },
    {
      title: "จำนวนครั้ง",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.numberTimes || 0, 0)}</div>,
    },
    {
      title: "Coin",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.coin || 0, 2)}</div>,
    },
    {
      title: "APP",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.mobile || 0, 2)}</div>,
    },
    {
      title: "QR Code",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.qrCode || 0, 2)}</div>,
    },
    {
      title: "รวม",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.total || 0, 2)}</div>,
    },
  ];

  return (
    <div className="dash-chart-box-container">
      <div className="dash-space-between">
        <div className="h-d-t-g">
          <div className="f-c-c">
            <i className="bi bi-journal-text" style={{ lineHeight: "0" }}></i>
          </div>
          <div className="f-s-c">รายงานการใช้งานแยกตามเครื่อง</div>
        </div>

        <div className="dash-table-container">
          <Table
            bordered
            size="small"
            rowKey={(record) => record.id}
            loading={isLoading}
            dataSource={dataListAll}
            columns={columnsGraphQL}
            pagination={false}
            scroll={{ x: true }}
          />
        </div>

        <div className="pgnt-st">
          <Pagination
            size="small"
            current={currentPage}
            onChange={(page) => setCurrentPage(page)}
            total={reportDevice?.length}
            // pageSize={dataListAll?.length ?? perPage}
            pageSize={perPage}
            showSizeChanger={false}
          />
        </div>
      </div>
    </div>
  );
};

export default TableReportMachineV2;
