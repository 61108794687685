import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Table,
  DatePicker,
  Select,
  Input,
  Pagination,
  Tooltip,
  Modal,
} from "antd";
import "./Payment.css";
import XLSX from "xlsx";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import "moment/locale/th";

import {
  numberWithCommas,
  openNotification,
  getSearchParams,
} from "../../utils/function";
import {
  apiPMGetPayment,
  apiPMApproveRejectPayment,
} from "../../services/apis/payment";
import { apiSCBTransactionId } from "../../services/apis/transaction";
import { get_history, export_history } from '../../services/apis/mobileApp'
const { RangePicker } = DatePicker;
const { Option } = Select;

const Payment = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const pmt = params.get("pmt");
  const start = params.get("start");
  const end = params.get("end");
  const sw = params.get("sw");

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);
  const perPage = 20;
  const [startDate, setStartDate] = useState(
    start
      ? moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    end
      ? moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [tableData, setTableData] = useState(undefined);
  console.log(tableData?.totalPages);
  const [transactionlength, setransactionlength] = useState(0);

  const [filterOption, setFilterOption] = useState(pmt || "");
  const [searchWord, setSearchWord] = useState(sw || "");
  const [searchButton, setSearchButton] = useState(false);
  const [waitingForCSV, setWaitingForCSV] = useState(false);

  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalId, setModalId] = useState(undefined);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const [visibleTnxId, setVisibleTnxId] = useState(false);
  const [transactionId, setTransactionId] = useState("");

  useEffect(() => {
    getTableData();
    // history.push(
    //   `${location.pathname}${getSearchParams(
    //     ["p", "ttl", "pmt", "start", "end", "sw"],
    //     [currentPage, perPage, filterOption, startDate, endDate, searchWord]
    //   )}`
    // );
  }, [currentPage, perPage, searchButton]);

  const convertToISOString = (dateString) => {
    const date = new Date(dateString.replace(" ", "T") + "Z");
    return date.toISOString();
  };
  const getTableData = async () => {
    setIsLoading(true);
    const startDate_isoDate = convertToISOString(startDate);
    const endDate_isoDate = convertToISOString(endDate);
    let data = {
      startDate: startDate_isoDate,
      endDate: endDate_isoDate
    }
    console.log(data);
    let payment = await get_history(
      data
    );
    if (payment && payment.status) {
      setIsLoading(false);
      setCurrentPage(1);
      setTableData(payment);
      setransactionlength(payment.totalPages)
    }
  };


  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "วันที่/เวลา",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>{data.eventdate ? moment(data.eventdate).format("DD-MM-YYYY HH:mm:ss") : "-"}</div>
      ),
    },
    {
      title: "ลูกค้า",
      ellipsis: true,
      className: "cs-pt",
      render: (data) => (
        <div
          className="cs-pt cl-g"
        >
          {data.customer_info.name || " "}
          {data.customer_info.tel || " "}
        </div>
      ),
    },
    {
      title: "โปรโมชั่นโค้ด",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{data.promotion || "-"}</div>,
    },
    {
      title: "ช่องทาง",
      ellipsis: true,
      align: "center",
      render: (data) =>

          <div>{data.tr_date || "-"}</div>
        
    },
    {
      title: "จำนวนเงิน",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{(data.value.$numberDecimal)}</div>,
    },
    // {
    //   title: "สถานะ",
    //   ellipsis: true,
    //   align: "center",
    //   render: (data) => <div>{data.status || "-"}</div>,
    // },
    // {
    //   title: "ยืนยัน",
    //   ellipsis: true,
    //   align: "center",
    //   fixed: "right",
    //   render: (data) =>
    //     (data.status || "").toUpperCase() === "APPROVE" ||
    //       (data.status || "").toUpperCase() === "REJECT" ||
    //       (data.status || "").toUpperCase() === "COMPLETE" ? (
    //       <div>-</div>
    //     ) : (
    //       <div className="f-c-c tb-cl-a">
    //         <Tooltip placement="top" title="Approve">
    //           <div
    //             className="f-c-c d-b-t-n-s b-c-o-i"
    //             style={{ backgroundColor: "#00ADEF" }}
    //             onClick={(e) => {
    //               e.stopPropagation();
    //               setModalId(data.payment_id);
    //               setModalTitle("Approve");
    //               setVisible(true);
    //             }}
    //           >
    //             <i className="bi bi-check-circle"></i>
    //           </div>
    //         </Tooltip>
    //         <Tooltip placement="top" title="Reject">
    //           <div
    //             className="f-c-c d-b-t-n-s b-c-o-i"
    //             style={{ backgroundColor: "#ED2228" }}
    //             onClick={(e) => {
    //               e.stopPropagation();
    //               setModalId(data.payment_id);
    //               setModalTitle("Reject");
    //               setVisible(true);
    //             }}
    //           >
    //             <i className="bi bi-x-circle"></i>
    //           </div>
    //         </Tooltip>
    //       </div>
    //     ),
    // },
  ];


  const toCSVFile = async () => {
    setWaitingForCSV(true);
    const startDate_isoDate = convertToISOString(startDate);
    const endDate_isoDate = convertToISOString(endDate);
    let date = {
      startDate: startDate_isoDate,
      endDate: endDate_isoDate
    }
    let data = await export_history( date );
    let csvData = [];

    if (data && data.status) {
      (data.credits || []).map((data, idx) => {
        let resultRow = {};
        resultRow["วันที่"] = data.eventdate ? moment(data.eventdate).format("DD-MM-YYYY HH:mm:ss") : "-" ;
        resultRow["ลูกค้า"] = data.customer_info.name || "-";
        resultRow["เบอร์"] = data.customer_info.tel || "-";
        resultRow["โปรโมชั่นโค้ด"] = data.promotion || "-";
        resultRow["ช่องทาง"] = data.tr_time || "-";
        resultRow["จำนวนเงิน"] = data.value.$numberDecimal || "0";
        resultRow["สถานะ"] = data.action;
        resultRow["Mac"] = data.machine_no || "";
        
        csvData.push(resultRow);
      });

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = "Topup-Credit";
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false);
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Payment</div>

      <div className="h-d-t-g-w-d-d-s">
        <div className="h-d-t-g-w-d-d-s-order-2">
          <Select
            value={filterOption}
            style={{ width: "100%" }}
            onChange={(value) => {
              setCurrentPage(1);
              setFilterOption(value);
            }}
          >
            <Option value="">All Payment Type</Option>
            <Option value="qr_test">TW Pay</Option>
            <Option value="linepay">Line Pay</Option>
            <Option value="truemoney">True Money Wallet</Option>
            <Option value="omise">Credit Card</Option>
            <Option value="alipay">Alipay</Option>
            <Option value="scb">Thai QR-Code</Option>
            <Option value="admin">Admin</Option>
          </Select>
        </div>
        <div className="h-d-t-g-w-d-d-s-order-1">
          <RangePicker
            style={{ width: "100%" }}
            // format={(date) =>  moment(date).add(543, "years").format("D MMM YYYY HH:mm") }
            allowClear={false}
            showTime={{ format: "HH:mm" }}
            value={[
              moment(startDate, "YYYY-MM-DD HH:mm:ss"),
              moment(endDate, "YYYY-MM-DD HH:mm:ss"),
            ]}
            onChange={(date) => {
              setCurrentPage(1);
              setStartDate(
                moment(date[0]).startOf("second").format("YYYY-MM-DD HH:mm:ss")
              );
              setEndDate(
                moment(date[1]).endOf("second").format("YYYY-MM-DD HH:mm:ss")
              );
            }}
            disabledDate={(current) =>
              current && current >= moment().endOf("day")
            }
          />
        </div>
        <div className="h-d-t-g-w-d-d-s-order-3">
          <Input
            placeholder="search .."
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1);
              setSearchButton(!searchButton);
            }}
          />
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-d-t-g-w-d-d-s-order-4"
          onClick={() => {
            setCurrentPage(1);
            setSearchButton(!searchButton);
          }}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-32 h-d-t-g-w-d-d-s-order-5"
          style={{ pointerEvents: waitingForCSV ? "none" : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : "Export CSV"}
        </div>
      </div>
      <Table
        bordered
        size="small"
        loading={isLoading}
        rowKey={(record) => record.payment_id}
        dataSource={(tableData && tableData.credits) || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />

      {/* <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={(page) => setCurrentPage(page)}
          total={transactionlength}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div> */}
      

      {/* <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: "#FFCB05" }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Confirm to {modalTitle} ?</div>
        <div className="m-b-y-n-g">
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: "#cccccc" }}
            onClick={() => setVisible(false)}
          >
            Cancel
          </div>
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{
              backgroundColor: "#3f4d67",
              pointerEvents: isLoadingConfirm ? "none" : null,
            }}
            onClick={() => approveReject()}
          >
            {isLoadingConfirm ? <LoadingOutlined /> : "Confirm"}
          </div>
        </div>
      </Modal> */}

      {/* <Modal
        title=""
        visible={visibleTnxId}
        closable={false}
        footer={false}
        centered
      >
        <div className="f-c-c">Transaction ID = {transactionId}</div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t md-btn-ol-cc"
          onClick={() => {
            setVisibleTnxId(false);
          }}
        >
          OK
        </div>
      </Modal> */}
    </div>
  );
};

export default Payment;
